@import url(https://use.typekit.net/znh6lde.css);

// Vars
$primary-color: #ffffff;
$dark-primary: #333333;

$background: #fff;
$background-light: #fff;

$font-color: #111111;

// Accent Colors
$accent-1: #C7EBB3;
$accent-2: #F4DFCD;

// Reset
*{
    font-family: 'Poppins', monospace;
}

body{
    background: $background;
    margin: 0;
    padding: 0;
}

h1{
    font-size: 3.0em;
    font-weight: 600;
}

h2{
    font-size: 2.5em;
    font-weight: 600;
}

h3{
    font-size: 2.0em;
    font-weight: 600;
}

h4{
    font-size: 1.5em;
    font-weight: 600;
}

h5{
    font-size: 1.2em;
    font-weight: 600;
}

h6{
    font-size: 1.0em;
    font-weight: 600;
}

p{

}

// Website inner
.website-inner{
    height: 100vh;

    background: $background-light;
}

// Auth Website inner
.auth-website-inner{
    background: $background-light;
    min-height: 100vh;
}

// Buttons
.btn{
    font-weight: bold;
    margin-right: 15px;

    padding-right: 15px;
    padding-left: 15px;

    min-width: 48px;
    min-height: 48px;

    line-height: 2.1em;

    background: #000000;
    color: #ffffff;
    border: 2px solid #000000;

    span{
        padding-left: 3px;
        margin-top: -3px;

        position: relative;
        top: -1px;
    }

}

.btn:hover, .btn.unset {
    cursor: pointer;
    color: #000000;
    border: 2px solid #000000;
    background: white;
}

.btn-round{
    border-radius: 28px;
}

.btn-active{
    background: #ffffff;
    color: $font-color;

    border: 2px solid $font-color;
}

// Header
.header{
    background: transparent;
    height: 80px;

    .header-inner {
        @media screen and (max-width: 768px) {
            padding: 0px 30px;
        }

        .header-row{

            .header-menu-action{
                padding-left: 0px;

                .header-menu-action-inner{
                    padding: 10px;
                    padding-top: 15px;

                    padding-left: 0px;

                    button{
                        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                        background: #000;
                        color: white;
                        outline: none;
                        border: none;
                        border-radius: 50%;

                        height: 45px;
                        width: 45px;
                        transition: all 0.2s ease-in-out;
                    }

                    button:hover{
                        cursor: pointer;
                    }

                    button.header-menu-action-inner-button-active{
                        background: #fff;
                        color: #000;
                        transition: all 0.2s ease-in-out;
                    }

                    h2{
                        font-size: 2.0em;
                        font-weight: 400;
                        
                        position: relative;
                        top: 5px;
                        left: 15px;

                        margin: 0px;
                        padding: 0px;
    
                        color: #333;
    
                        text-align: left;

                        display: inline-block;

                        a{
                            text-decoration: none;
                            color: $font-color;
                        }
                    }
                }
            }

            .header-interactions{

                .header-search{
                    padding-top: 20px;
                    padding-right:5px;

                    .header-links {
                        text-align: right;

                        ul {

                            li {
                                display: inline-block;
                                margin-left: 5px;

                                a {}

                                a.btn-login {
                                    background: white;
                                    color: $font-color;
                                }
                            }
                        }
                    }

                    input {
                        @media screen and (max-width: 768px) {
                            display: none;
                        }
                        border: none;
                        border-radius:6px;
                        padding: 5px;
                        padding-left: 15px;
                        width: 180px;

                        float: right;

                        background: rgba(0,0,0,.2);

                        transition: all 0.2s ease-in-out;
                    }

                    input::placeholder{
                        color: white;
                    }

                    input:focus{
                        background: white;
                        outline: none;
                    }

                    input:focus::placeholder{
                        color: #333;
                    }

                }
            }
        }
    }
}

// Footer
.footer{
    padding: 20px;

    position: absolute;
    bottom: 0px;


    p {
        @media screen and (max-width: 768px) {
            text-align: center;
            font-size: 3vmin;
        }

        padding: 0px;
        margin: 0px;

        color: white;

        font-weight: bold;
    }
}

// Sidebar
.sidebar.sidebar-active{
    display: block;
    transition: all 0.2s ease-in-out;
}

.sidebar {
    @media screen and (max-width: 768px) {
        width: 90%;
        left: 20px;
    }
    background: $primary-color;

    width: 225px;

    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

    position: fixed;
    left: 45px;
    top: 80px;

    display: none;

    .sidebar-inner{

        .sidebar-top{
            min-height: 100px;
            padding: 15px;

            text-align: center;

            border-bottom: 1px solid rgba(0,0,0,0.1);

            h2{

            }

            h4{
                font-size: 1em;
                text-align:center;
                font-weight: 600;
            }
        }

        .sidebar-middle{

            padding: 20px;

            .sidebar-middle-item{

                .btn{
                    width: 100%;
                    margin-bottom: 10px;

                    i{
                        position: relative;
                        top:1px;
                    }
                }

                .sidebar-middle-item-user{
                   padding: 35px;
                   padding-top: 10px;


                    ul{
                        padding: 0px;
                        margin: 0px;

                        li{
                            color: #333;
                            font-size: 1.2em;

                            padding-bottom: 10px;

                            span{

                                i{
                                    font-size: .8em;
                                }
                            }

                            a{
                                color: #333;
                                text-decoration: none;

                                font-weight: 500;

                            }
                        }
                    }
                }
            }
        }

        .sidebar-bottom{

            width:225px;

            padding: 20px;

            .sidebar-bottom-item{

                .sidebar-bottom-item-user{
                   padding: 25px;

                    ul{
                        padding: 0px;
                        margin: 0px;

                        li{
                            color: #333;

                            a{
                                color: #333;
                                text-decoration: none;
                                font-weight: 600;

                            }
                        }
                    }
                }

                .sidebar-bottom-item-signout{

                    button{
                        text-decoration: none;
                        color: #333;

                        font-weight: 600;

                        padding: 10px;
                        text-align: center;
                        display: block;

                        border: 2px solid #333;
                        width: 100%;

                        border-radius: 5px;
                        background: white;
                    }
                }
            }
        }
    }
}

// Page frontend logic
.page{
    padding-top: 50px;
}

// Page: View Note
.page-notes-head {
    @media screen and (max-width: 768px) {
        padding: 0px 20px;
        padding-top: 15px;
    }

    .page-notes-head-title{
        border-bottom: 1px solid #aaa;
        padding: 0px;

        h1:first-child{
            font-size: 3em;
            padding-bottom: 30px;
        }

        h1{
            font-weight: 400;
            font-size: 2.5em;

            span{
                font-size: .5em;
                font-weight: 400;
            }

            input{
                width: 100%;
                padding: 10px;
                padding-left: 15px;

                border: 2px solid #eee;
                border-radius: 10px;

            }
        }

        ul{
            margin: 0px;
            padding: 0px;
            margin-bottom: 15px;

            li{
                display: inline-block;
                padding-right: 25px;
                color: rgb(107 114 128/1);

                font-weight: 400;
            }
        }

        .page-notes-head-actions{
            width: fit-content;
            border-radius: 32px;

            margin-top: 35px;
            margin-bottom: 15px;

                a{
                    background: #000000;
                    color: #ffffff;

                    border: 2px solid transparent;

                    font-weight: 400;

                    border-radius: 28px;

                }

                a:hover{
                    background: #ffffff;
                    color: $font-color;
                    border: 2px solid $font-color;
                }

                a:last-child{
                    margin-right: 0px;
                }

                a.bookmark-active{
                    color: #feba57;
                }
        }
    }
}

.page-notes-view{

    .page-notes-view-inner {
        @media screen and (max-width: 768px) {
            padding: 0px 20px;
        }
        padding: 0px;

        .page-notes-view-note{
            background: $primary-color;

            border-radius: 5px;

            .page-notes-view-note-title{
                padding-bottom: 10px;

                h1{

                    input{
                        width: 100%;
                        padding-left: 5px;

                        border: 2px solid #eee;
                        border-radius: 10px;
                    }
                }

                h2{

                    input{
                        width: 100%;
                        padding-left: 5px;
                    }
                }

                ul{
                    padding: 0px;
                    margin: 0px;

                    li{
                        display: inline;
                        margin-right: 15px;

                        font-weight: bold;
                    }
                }
            }

            .page-notes-view-note-actions{
                margin-top: 15px;
                border-top: 2px solid #eee;
                padding-top: 15px;

                button{
                    margin-right: 15px;
                }
            }

            .page-notes-view-note-content{
                padding-top: 10px;

                p{
                    img{
                        max-width: 50%;
                        display: block;
                        padding-bottom: 15px;
                    }
                }

                .page-notes-view-note-content-actions{
                    padding-top: 15px;
                    margin-top: 20px;

                    button{

                    }
                }
            }
        }
    }
}

// Page: Settings
.page-settings{

    .page-settings-inner{
        padding: 0px 20px;

        .page-settings-row{
            background: $primary-color;
            padding: 20px;

            border-radius: 5px;
            box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

        }
    }
}

// Page: Timeline
.page-timeline{

    .page-timeline-inner {
        @media screen and (max-width: 768px) {
            padding: 0px 20px;
        }
        padding: 0px 00px;

        .page-timeline-header{
            border-bottom: 1px solid rgba(0,0,0,.5);
            margin-bottom: 15px;

            h2 {
                @media screen and (max-width: 768px) {
                    font-size: 12vw;
                }

                padding-bottom: 20px;
                padding-top: 30px;

                font-size: 4.4em;
                font-weight: 500;

                line-break: strict;
            }

            .page-timeline-header-actions{
                padding-bottom: 15px;

                ul{
                    padding: 0px;
                    margin: 0px;

                    li{
                        list-style: none;
                        display: inline-block;

                        a{

                        }
                    }
                }
            }
        }

        .page-timeline-all-notes{
            display: flex;
            flex-wrap: wrap;
            gap: 30px;

            .note {
                @media screen and (max-width: 768px) {
                    flex-basis: 100%;
                }
                flex-grow: 0;
                flex-basis: 31.5%;
            }
        }
    }
}

// Page: Sign In
.sign-in-main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 768px) {
        width: 80%;
    }

    width: 400px;
    background: white;

    margin: auto;

    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    border: 1px solid #eee;

    .sign-in-main-inner{
        padding: 50px 55px;

        .sign-in-main-header{

            .sign-in-main-header-inner{
                text-align: center;
                border-bottom: 1px solid #aaa;

                padding-bottom: 25px;

                img{
                    width: 150px;
                    border-radius: 5px;
                }

                h2 {
                    @media screen and (max-width: 768px) {
                        font-size: 1.2em;
                    }
                    padding-top: 25px;
                    font-weight: bold;

                }

                span {
                    @media screen and (max-width: 768px) {
                        font-size: .8em;
                        line-height: 1px !important;
                    }
                }
            }
        }

        .sign-in-main-body{
            padding-top: 25px;

            .show-btns{

                .btn{
                    width: 100%;
                    margin-bottom: 10px;
                }

                .btn:last-child{
                    margin-bottom: 0px;
                }
            }

            .login_submit_btn {
                width: 100%;
                background: #333;
                color: #fff;

                font-weight: bold;
            }
        }
    }
}

// Note componant
.note{
    background: $accent-1;
    padding: 20px;

    border-radius: 15px;
    cursor: pointer;

    height: fit-content;

    .note-header{

        h6{

        }
    }

    .note-body{

        p{
            font-size: .9em;
            padding-top: 10px;
            padding-bottom: 10px;
        }

        .note-body-tags{

        }

        .note-body-actions{
            text-align: right;

            .note-body-actions-date{
                font-size: 1em;
                float: left;
                align-self: baseline;

                p{
                    margin-bottom: 0px;
                    padding-bottom: 0px;
                }
            }

            .note-body-actions-btns{
                text-align: left;

                a{
                    background: #000000;
                    color: #ffffff;

                }

                a:hover{
                    background: #ffffff;
                    color: $font-color
                }

                a:last-child{
                    margin-right: 0px;
                }

                a.bookmark-active{
                    color: #feba57;
                }
            }
        }
    }
}